import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../utils/apk";

const OrderDetails = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedDeliveryBoy, setSelectedDeliveryBoy] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch orders from the API using Axios
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(API + "/get/all/order");
        setOrders(response.data.data); // Assuming response.data.data contains the list of orders
        setLoading(false);
      } catch (err) {
        console.error("Error fetching orders:", err);
        setError("Failed to fetch orders");
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Fetch delivery boys from the API using Axios
  const fetchDeliveryBoys = async () => {
    try {
      const response = await axios.get(
        API + "/get/all/delivery/boy"
      );
      setDeliveryBoys(response.data); // Assuming the response data contains delivery boys
    } catch (err) {
      console.error("Error fetching delivery boys:", err);
      setError("Failed to fetch delivery boys");
    }
  };

  // Handle the opening of the modal
  const openModal = (orderId) => {
    setSelectedOrder(orderId);
    fetchDeliveryBoys(); // Fetch delivery boys when the modal opens
    setIsModalOpen(true);
  };

  // Handle the closing of the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handle the selection of a delivery boy
  const selectDeliveryBoy = (deliveryBoy) => {
    setSelectedDeliveryBoy((prev) => ({
      ...prev,
      [selectedOrder]: deliveryBoy.name, // Set the delivery boy for the selected order
    }));
    setIsModalOpen(false); // Close modal after selection
  };

  // Loading or Error Handling
  if (loading) {
    return <p className="text-center mt-20 text-gray-500">Loading orders...</p>;
  }

  if (error) {
    return <p className="text-center mt-20 text-red-500">{error}</p>;
  }

  return (
    <div className="bg-gradient-to-b from-blue-100 to-blue-200 min-h-screen py-10">
      <h1 className="text-5xl font-bold text-center text-gray-800 mb-10">
        Order Details
      </h1>

      <div className="max-w-6xl mx-auto px-4">
        {orders.map((order) => (
          <div
            key={order._id}
            className="bg-white shadow-xl rounded-lg mb-8 p-6 hover:shadow-2xl transition duration-300 transform hover:scale-105"
          >
          <div>
            <h2 className="text-3xl font-semibold text-gray-800 mb-2">
              Order ID: {order.orderId}
            </h2>

            {order.createdAt && (
              <p className="text-gray-600">
                <strong>Order Placed On:</strong>{" "}
                {new Date(order.createdAt).toLocaleDateString()}{" "}
                {new Date(order.createdAt).toLocaleTimeString()}
              </p>
            )}
            <p className="text-xl font-medium text-indigo-600 mb-4">
              Total Price: ${order.totalPrice?.toFixed(2)}
            </p>
            </div>
            {/* User Address */}
            <div className="mb-6">
              <h3 className="text-2xl font-medium text-gray-700 mb-2">
                User Address
              </h3>
              <p className="text-gray-600">
                <strong>Name:</strong> {order.userAddress?.name}
              </p>
              <p className="text-gray-600">
                <strong>Address:</strong> {order.userAddress?.address}
              </p>
              <p className="text-gray-600">
                <strong>Mobile:</strong> {order.userAddress?.mobile}
              </p>
              <p className="text-gray-600">
                <strong>Pin Code:</strong> {order.userAddress?.pinCode}
              </p>
            </div>

            {/* Items in the Cart */}
            <div className="mb-6">
              <h3 className="text-2xl font-medium text-gray-700 mb-2">
                Items in Cart
              </h3>
              {order.addToCart?.cartIds?.map((item) => (
                <div
                  key={item._id}
                  className="flex items-center space-x-4 bg-gray-50 p-4 rounded-lg shadow-md mb-4"
                >
                  {/* Display image if available */}
                  {item.image ? (
                    <img
                      src={item.image}
                      alt={item.itemName}
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-20 h-20 bg-gray-200 rounded-lg" />
                  )}
                  <div>
                    <p className="text-xl text-gray-700 font-semibold">
                      {item.itemName}
                    </p>
                    <p className="text-gray-600">
                      <strong>Quantity:</strong> {item?.count}
                    </p>
                    <p className="text-gray-600">
                      <strong>Price:</strong> ${item.itemRate?.toFixed(2)}
                    </p>
                    {item.createdAt && (
                      <p className="text-gray-600">
                        <strong>Added On:</strong>{" "}
                        {new Date(item.createdAt).toLocaleDateString()}{" "}
                        {new Date(item.createdAt).toLocaleTimeString()}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Order Status */}
            <div className="mb-4">
              <h3 className="text-2xl font-medium text-gray-700 mb-2">
                Order Status
              </h3>
              <p className="text-gray-600">
                Status:{" "}
                <span
                  className={`inline-block px-4 py-2 rounded-full text-white ${getStatusColor(
                    order.status
                  )}`}
                >
                  {order.status}
                </span>
              </p>
            </div>

            {/* Selected Delivery Boy */}
            {selectedDeliveryBoy[order._id] && (
              <p className="text-xl text-green-600">
                Delivery Boy: {selectedDeliveryBoy[order._id]}
              </p>
            )}

            {/* Add Delivery Boy Button */}
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600"
              onClick={() => openModal(order._id)}
            >
              Add Delivery Boy
            </button>
          </div>
        ))}
      </div>

      {/* Delivery Boy Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h3 className="text-2xl font-semibold mb-4">Select Delivery Boy</h3>
            <ul>
              {deliveryBoys.map((boy) => (
                <li
                  key={boy._id}
                  className="flex items-center mb-4 cursor-pointer hover:bg-gray-100 p-2 rounded"
                  onClick={() => selectDeliveryBoy(boy)}
                >
                  <img
                    src={boy.uploadSelfie}
                    alt={boy.name}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <p className="text-lg font-medium">{boy.name}</p>
                    <p className="text-gray-600">{boy.mobileNo}</p>
                  </div>
                </li>
              ))}
            </ul>
            <button
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg shadow hover:bg-red-600"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Helper function to set status badge colors
const getStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case "pending":
      return "bg-yellow-500";
    case "completed":
      return "bg-green-500";
    case "cancelled":
      return "bg-red-500";
    default:
      return "bg-gray-500";
  }
};

export default OrderDetails;
